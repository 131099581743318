import { useEffect, useMemo, useState } from "react";

const dotParentClassNames = "dot absolute inset-[2px] z-10 content-['']";

const dotClassNames =
  "absolute w-[20px] h-[20px] z-50 left-[50%] rounded-[50%]";

const getReponsiveDimension = (
  parentWidth: number,
  standartElementWidth: number
) => {
  if (parentWidth > 1729) return standartElementWidth;

  if (parentWidth > 1399) return standartElementWidth / 1.25;

  if (parentWidth > 1024) return standartElementWidth / 1.5;

  if (parentWidth > 800) return standartElementWidth / 1.75;

  return standartElementWidth / 2;
};

interface Props {
  percentage: number;
  text: string;
  color: string;
}
export const ProgressBar = ({ percentage, text, color }: Props) => {
  const [parentState, setParentState] = useState({
    loading: true,
    width: 0,
  });

  const getDimensions = useMemo(() => {
    return {
      circle: getReponsiveDimension(parentState.width, 480),
      svg: getReponsiveDimension(parentState.width, 234.52),
      circleStroke: getReponsiveDimension(parentState.width, 1474),
      dot: getReponsiveDimension(parentState.width, 5),
    };
  }, [parentState.width]);

  const rotationValue = 3.6 * percentage + "deg";

  const resizeObserver = new ResizeObserver((entries) => {
    if (entries.length < 1) return;

    const parent = entries[0].target;
    setParentState({
      loading: false,
      width: parent.clientWidth,
    });
  });

  useEffect(() => {
    const parent = document.getElementsByClassName("progressBarWrapper")[0];
    if (!parent) {
      setParentState({
        loading: false,
        width: 0,
      });
    }
    resizeObserver.observe(parent);
  }, []);

  if (parentState.loading) return <h1>Loading...</h1>;

  return (
    <div
      className="percent relative aspect-square"
      style={{ width: getDimensions.circle, height: getDimensions.circle }}
    >
      {percentage !== 100 && (
        <div className={dotParentClassNames} style={{ rotate: rotationValue }}>
          <div
            className={dotClassNames}
            style={{
              backgroundColor: color,
              boxShadow: "0 0 10px " + color + ", 0 0 30px " + color,
              top: -5 - getDimensions.dot > -9 ? -5 - getDimensions.dot : -5,
            }}
          ></div>
        </div>
      )}

      <svg
        className="relative rotate-[270deg] aspect-square"
        style={{ width: getDimensions.circle, height: getDimensions.circle }}
      >
        <circle
          cx={getDimensions.svg}
          cy={getDimensions.svg}
          r={getDimensions.svg}
          fill="transparent"
          className="w-full h-full stroke-[6] stroke-disabled"
          style={{
            transform: `translate(${getDimensions.dot}px, ${getDimensions.dot}px)`,
          }}
        />
        <circle
          cx={getDimensions.svg}
          cy={getDimensions.svg}
          r={getDimensions.svg}
          fill="transparent"
          strokeDasharray={getDimensions.circleStroke}
          strokeDashoffset={
            getDimensions.circleStroke -
            (getDimensions.circleStroke * percentage) / 100
          }
          stroke={color}
          className="w-full h-full stroke-[6]"
          style={{
            color,
            transform: `translate(${getDimensions.dot}px, ${getDimensions.dot}px)`,
          }}
        />
      </svg>
      <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[400px]">
        <p className="text-center text-[4.31rem]" style={{ color: color }}>
          {percentage}%
        </p>
        <p className="text-[1.5rem] text-center font-medium">{text}</p>
      </div>
    </div>
  );
};
