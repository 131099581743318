import { useMemo, useState } from "react";
import { IOption } from "./lib/Interfaces";
import "assets/styles/input.css";
import arrow from "../../../../src/assets/icons/DownArrow.svg";
import classNames from "classnames";

interface IProps {
  title?: string;
  options: IOption[];
  defaultOption?: IOption;
}
export const InputSelect = ({ title, options, defaultOption }: IProps) => {
  const [visibleOptions, setVisibleOptions] = useState(false);
  const [selected, setSelected] = useState<IOption>(
    () => defaultOption || options[0]
  );

  const filteredOptions = useMemo(() => {
    return options.filter((item) => item !== selected);
  }, [selected]);

  const closeDropdown = () => {
    setVisibleOptions(false);
  };

  const selectHandler = (option: IOption) => {
    setSelected(option);
    closeDropdown();
  };

  const switchDropDown = () => {
    setVisibleOptions((current) => !current);
  };

  const selectClasses = classNames(
    "relative flex items-center justify-between w-[20.5rem] h-[6.5rem] bg-white  pl-[38px]  pr-[34px]",
    { "rounded-t-[3.3rem]": visibleOptions },
    { "rounded-[3.3rem]": !visibleOptions }
  );
  return (
    <div>
      {title && (
        <h6 className="text-dark text-[1.75rem] opacity-[70%] mb-[1.68rem] max-w-[23rem]">
          {title}?
        </h6>
      )}

      <div className="relative w-[20.5rem] cursor-pointer">
        <div className={selectClasses} onClick={switchDropDown}>
          <span className="text-[1.375rem] font-semibold text-[#575656]">
            {selected.label}
          </span>
          <img src={arrow} className="h-auto w-[10px] hover:cursor-pointer" />
        </div>
        {visibleOptions && (
          <div className="absolute top-[100%] left-0 w-full bg-active flex flex-col rounded-b-[53px]">
            {filteredOptions.map((option) => {
              return (
                <div
                  key={option.value}
                  className="w-full text-white text-center text-[1.375rem] font-semibold py-2 cursor-pointer hover:text-black"
                  onClick={() => selectHandler(option)}
                >
                  {option.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
