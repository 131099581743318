import { InputSelect } from "components/shared/InputSelect/InputSelect";
import { IQuestion } from "lib/interfaces/Interfaces";
import { useMemo, useState } from "react";
import arrow from "assets/icons/DownArrow.svg";

interface Props {
  question: IQuestion;
}
export const QuestionHandler = ({ question }: Props) => {
  const [selectedUnit, setSelectedUnit] = useState(question.unit);
  const [isUnitsTabVisible, setIsUnitsTabVisible] = useState(false);

  const unitOptions = useMemo(() => {
    if (!question.unitOptions || !question.unit) return [];

    const options = [question.unit, ...question.unitOptions];

    return options.filter((item) => item !== selectedUnit);
  }, [selectedUnit]);

  const switchUnitTabVisibility = () => {
    setIsUnitsTabVisible((current) => !current);
  };

  const closeUnitTab = () => {
    setIsUnitsTabVisible(false);
  };

  const selectUnit = (unit: string) => {
    setSelectedUnit(unit);
    closeUnitTab();
  };

  if (question.inputType === "select" && question.options) {
    return <InputSelect options={question.options} title={question.title} />;
  }

  return (
    <div>
      {question.title && (
        <h6 className="text-dark text-[1.75rem] opacity-[70%] mb-[1.68rem] max-w-[23rem]">
          {question.title}?
        </h6>
      )}

      <div className="flex items-center justify-between w-[16.25rem] h-[6.56rem] bg-white rounded-[8.83rem]  pl-[2.375rem]  pr-[2.125rem]">
        <input
          type={question.inputType}
          className="text-[1.375rem] h-[6.25rem] w-[7.5rem] focus:border-none outline-none z-[999]"
          onClick={closeUnitTab}
        />

        {question.unit && (
          <div className="relative flex flex-col text-[1.375rem] text-[#575656] font-bold">
            <div
              className="flex items-center gap-[0.75rem] cursor-pointer"
              onClick={switchUnitTabVisibility}
            >
              {selectedUnit}
              <img src={arrow} className="h-auto w-[0.625rem]" />
            </div>

            {isUnitsTabVisible && unitOptions.length && (
              <div className="absolute top-[100%] flex flex-col w-full bg-gray-200">
                {unitOptions.map((unit) => {
                  return (
                    <div
                      key={unit}
                      className="cursor-pointer text-active w-full"
                      onClick={() => selectUnit(unit)}
                    >
                      {unit}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
