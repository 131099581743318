import { ProgressBar } from "components/circle/ProgressBar";
import { QuestionHandler } from "components/questionHandler/QuestionHandler";
import { IStep } from "lib/interfaces/Interfaces";
import nextIcon from "assets/icons/NextArrow.svg";

interface IProps {
  step: IStep;
  onNext: () => void;
  onFinish: () => void;
  isLast: boolean;
}
export const Step = ({ step, onNext, isLast, onFinish }: IProps) => {
  const onNextHandler = () => {
    onNext();
  };

  const onFinishHandler = () => {
    onFinish();
  };

  return (
    <div className="container progressBarWrapper flex  flex-col xl:flex-row justify-center xl:justify-between items-center gap-[3rem] xl:gap-[12%] text-[12px] 3xl:text-[16px]">
      <ProgressBar
        percentage={step.percentage}
        color={step.color}
        text={step.title}
      />
      <div className="w-full ml-0 xl:ml-[7rem] 3xl:ml-[11.5rem] flex flex-col items-center">
        <div className="w-auto">
          <h4 className="text-[1.375rem] text-[#00000030] mb-[1.375rem] font-semibold">
            STEP {step.stepNumber}
          </h4>
          <h2 className=" max-w-[420px] text-[2.375rem] text-[#000000] mb-[3.25rem] font-semibold">
            {step.questionTitle}?
          </h2>
          <div className="flex flex-row gap-[3.125rem] flex-wrap">
            {step.questions.map((question, index) => {
              return <QuestionHandler key={index} question={question} />;
            })}

            <button
              onClick={isLast ? onFinishHandler : onNextHandler}
              className="group flex items-center gap-[1.5rem] text-success text-[1.625rem]  px-10 py-5 whitespace-nowrap cursor-pointer z-[9999]"
            >
              {isLast ? "LET'S GO" : "Next"}
              <img
                className="ease-in duration-300 group-hover:translate-x-[5px]"
                src={nextIcon}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
