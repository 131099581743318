import { IStep } from "./Interfaces";

export const filteringQuestions: IStep[] = [
  {
    stepNumber: 1,
    title: "General Informations",
    percentage: 5,
    color: "#82C91E",
    questionTitle: "How much funding are you looking for",
    questions: [{ inputType: "number", unit: "Eur", unitOptions: ["Lek"] }],
  },
  {
    stepNumber: 2,
    title: "General Informations",
    percentage: 10,
    color: "red",
    questionTitle: "What do you need the funds for",
    questions: [
      {
        inputType: "select",
        options: [
          { label: "Investment", value: "Investment" },
          { label: "Personal", value: "Personal" },
          { label: "Green Bussiness", value: "Green Bussiness" },
        ],
      },
    ],
  },
];

export const results = [
  {
    id: 0,
    isBestMatch: true,
    data: [
      { title: "Lloji i produktit", value: "Green Loan" },
      { title: "Interes", value: "5-7%" },
      { title: "Team up to", value: "7 vjet" },
    ],
  },
  {
    id: 1,
    isBestMatch: false,
    data: [
      { title: "Lloji i produktit", value: "Green Loan" },
      { title: "Interes", value: "5-7%" },
      { title: "Team up to", value: "7 vjet" },
    ],
  },
  {
    id: 2,
    isBestMatch: false,
    data: [
      { title: "Lloji i produktit", value: "Green Loan" },
      { title: "Interes", value: "5-7%" },
      { title: "Team up to", value: "7 vjet" },
    ],
  },
  {
    id: 3,
    isBestMatch: false,
    data: [
      { title: "Lloji i produktit", value: "Green Loan" },
      { title: "Interes", value: "5-7%" },
      { title: "Team up to", value: "7 vjet" },
    ],
  },
];

export const sortingButtons = [
  { title: "Price", value: "price" },
  { title: "Speed", value: "speed" },
];
