import { Step } from "components/Step/Step";
import { IStep } from "lib/interfaces/Interfaces";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const steps: IStep[] = [
  {
    stepNumber: 1,
    title: "General Informations",
    percentage: 5,
    color: "#82C91E",
    questionTitle: "How much funding are you looking for",
    questions: [{ inputType: "number", unit: "Eur", unitOptions: ["Lek"] }],
  },
  {
    stepNumber: 2,
    title: "General Informations",
    percentage: 10,
    color: "red",
    questionTitle: "What do you need the funds for",
    questions: [
      {
        inputType: "select",
        options: [
          { label: "Investment", value: "Investment" },
          { label: "Personal", value: "Personal" },
          { label: "Green Bussiness", value: "Green Bussiness" },
        ],
      },
    ],
  },
  {
    stepNumber: 3,
    title: "Expensed Informations",
    percentage: 46,
    color: "#7D42F5",
    questionTitle: "Do you mind sharing some other details with us",
    questions: [
      {
        title: "What is your annual turnover",
        inputType: "number",
        unit: "Eur",
        unitOptions: ["Lek"],
      },
      {
        title: "How long have you been in this business for",
        inputType: "select",
        options: [
          { label: "1-3Y", value: "1-3Y" },
          { label: "3-5Y", value: "3-5Y" },
          { label: "5-10Y", value: "5-10Y" },
        ],
      },
    ],
  },
  {
    stepNumber: 4,
    title: "Loan Informations",
    percentage: 68,
    color: "#82C91E",
    questionTitle: "What are you expenses per month",
    questions: [{ inputType: "number", unit: "Eur", unitOptions: ["Lek"] }],
  },
];

export const StepsWrapper = () => {
  const [activeStep, setActiveStep] = useState<IStep>(steps[0]);
  const navigate = useNavigate();
  const onNext = () => {
    const nextStep = steps.filter(
      (step) => step.stepNumber === activeStep.stepNumber + 1
    );

    if (nextStep.length) setActiveStep(nextStep[0]);
  };

  const onFinish = () => {
    navigate("results");
  };

  const isLastStep = useMemo(() => {
    const hasNextStep = steps.some(
      (step) => step.stepNumber === activeStep.stepNumber + 1
    );
    return !hasNextStep;
  }, [activeStep]);

  return (
    <div
      className="py-[50px] xl:py-0 min-h-screen flex items-center justify-center"
      style={{
        background:
          //"transparent linear-gradient(159deg, #FFFFFF00 0%, #040E33 100%) 0% 0% no-repeat padding-box",
          "linear-gradient(159deg, rgba(255,255,255,1) 42%, rgba(202,207,215,1) 100%)",
      }}
    >
      <Step
        onNext={onNext}
        onFinish={onFinish}
        isLast={isLastStep}
        step={activeStep}
      />
    </div>
  );
};
