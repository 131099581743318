import { ResultsCards } from "components/results-cards/ResultsCards";
import { ResultsBanner } from "components/results-banner/ResultsBanner";

export const Results = () => {
  return (
    <div className="">
      <ResultsBanner />
      <ResultsCards />
    </div>
  );
};
