import {
  filteringQuestions,
  results,
  sortingButtons,
} from "lib/interfaces/results";
import { QuestionHandler } from "../questionHandler/QuestionHandler";
import classNames from "classnames";
import { useCallback } from "react";

export const ResultsCards = () => {
  const getCardClassName = useCallback((isBestMatch: boolean) => {
    return classNames(
      "relative bg-[#F7F7F7] p-[1.55rem] pb-[2.125rem] w-full max-w-none lg:max-w-[48.5%] mb-[2.5rem]",
      {
        "border-[7px] border-lightSuccess": isBestMatch,
      }
    );
  }, []);

  return (
    <div className="container flex flex-col xl:flex-row  mt-[0.5rem] pb-[7.625rem]">
      <div className=" w-full lg:w-[80%] mx-auto mb-[3rem] xl:w-[30%] xl:mr-[2.3%] bg-[#F7F7F7] pl-[3.625rem] pt-[2.875rem] pb-[5.3rem] pr-[1.375rem] h-min">
        <h4 className="text-[1.625rem] font-[600] mb-[4.375rem] pl-[5px]">
          MODIFY YOUR SEARCH
        </h4>
        {filteringQuestions.map((item, index) => {
          return (
            <div>
              <p className="text-[1.375rem] mb-[3.875rem] font-medium">
                {item.questionTitle}?
              </p>
              <QuestionHandler question={item.questions[0]} />
              {index + 1 !== filteringQuestions.length && (
                <span className="block w-full h-[1px] mt-[1.625rem] bg-[#707070] opacity-[0.16] mb-[3rem]"></span>
              )}
            </div>
          );
        })}
        <button className="bg-active text-white text-[1.5rem] font-[600] w-full py-[1.56rem] mt-[4rem] rounded-[3.3rem] mb-[1.25rem]">
          Modify
        </button>
      </div>
      <div className="w-full lg:w-[80%] mx-auto xl:w-[67.7%] flex flex-col">
        <div className="bg-[#F7F7F7] flex items-center mb-[2rem] pt-[2.875rem] px-[2.45rem] pb-[2.4rem]">
          <h4 className="text-[1.625rem] font-[600] mr-[3.375rem]">SORT BY</h4>
          {sortingButtons.map((button) => {
            return (
              <button className="bg-white py-[1.25rem] px-[4.25rem] mr-[1rem] rounded-[3.3rem] text-[rgba(0,0,0,0.5)] font-[600] cursor-pointer">
                {button.title}
              </button>
            );
          })}
        </div>
        <div className="flex flex-wrap gap-[3%]">
          {results.map((item) => {
            return (
              <div className={getCardClassName(item.isBestMatch)}>
                {item.isBestMatch && (
                  <div className="absolute w-[95%] top-[-11%] left-[50%] translate-x-[-50%] bg-lightSuccess text-white text-center text-[1.5rem] font-[600] py-[1.56rem] mt-[1.875rem] rounded-[3.3rem] mb-[1.25rem] z-[999]">
                    BEST MATCH FOR YOU
                  </div>
                )}
                <div className="bg-white w-full  flex items-center justify-center">
                  <h4 className="text-center text-[1.43rem] font-medium py-[4.75rem]">
                    CLICK TO REVEAL
                  </h4>
                </div>
                <div className="flex justify-between mt-[3.125rem]">
                  <div>
                    {item.data.map((data) => (
                      <h4 className="text-[1.3rem] font-[600] mb-[1.375rem]">
                        {data.title}
                      </h4>
                    ))}
                  </div>
                  <div className="flex flex-col items-end">
                    {item.data.map((data) => (
                      <h4 className="text-[1.3rem] font-[600] mb-[1.375rem]">
                        {data.value}
                      </h4>
                    ))}
                  </div>
                </div>
                <button className="bg-active text-white text-[1.5rem] font-[600] w-full py-[1.56rem] mt-[1.875rem] rounded-[3.3rem] mb-[1.25rem]">
                  APPLY
                </button>
                <span className="block text-center font-[600] text-[1.125rem]">
                  This bank is supported by a Guarantee Fund
                </span>
              </div>
            );
          })}

          {/* <div className="bg-[#F7F7F7] p-[1.55rem] pb-[2.125rem] w-full max-w-[48.5%] mb-[2.5rem]">
            <div></div>
            <div className="bg-white w-full  flex items-center justify-center">
              <h4 className="text-center text-[1.43rem] font-medium py-[4.75rem]">
                CLICK TO REVEAL
              </h4>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
