import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Questionnaire } from "pages/questionnaire/Questionnaire";
import { Results } from "pages/results/Results";

export const Main = () => {
  return (
    <section>
      <Router>
        <Routes>
          <Route path="" element={<Questionnaire />} />
          <Route path="/results" element={<Results />} />
        </Routes>
      </Router>
    </section>
  );
};
