import { ProgressBar } from "components/circle/ProgressBar";

export const ResultsBanner = () => {
  const numberResults = 1;
  return (
    <div
      style={{
        background:
          "transparent linear-gradient(180deg, #FFFFFF00 0%, rgba(202,207,215,0.35) 85%, #FFFFFF00 85%) 0% 0% no-repeat padding-box",
      }}
    >
      <div className="progressBarWrapper container pt-[11rem] flex justify-between items-start">
        <div className="pt-[1rem]">
          <h1 className="text-active text-[4.8rem] font-bold leading-[4rem]">
            Great news!
          </h1>
          <h3 className="text-[3.1rem] font-bold">{`We've found ${numberResults} funders.`}</h3>
        </div>
        <div>
          <ProgressBar percentage={100} color={"#54C64B"} text={"Completed"} />
        </div>
      </div>
    </div>
  );
};
