import { Main } from "components/layout/main/Main";
// import Footer from "./components/layout/footer/Footer";
// import Header from "./components/layout/header/Header";

function App() {
  return (
    <div className="App relative">
      {/* <Header /> */}
      <Main />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
